import React from 'react'

import './styles/Footer.css'

import PlaceIcon from '@mui/icons-material/Place'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import MailOutline from '@mui/icons-material/MailOutline'

import marker from '../../assets/icons/marker-list.svg'
import ribbon from '../../assets/images/footer-ribbon.png'

import CustomTypo from '../custom/components/CustomTypo'

import { companyInfo, companyServices } from '../../data/data'

const Footer = () => {
  return (
    <div>
      <div className="footer-content-container">
        <div className="footer-content">
          <div className="col-sm-3">
            <div>
              <img src={ribbon} alt="ribbon.png" className="ribbon-footer" />
              <div className="ribbon-text">
                <CustomTypo
                  type={'footerText'}
                  text={
                    'Nous utilisons des produits de nettoyage non nocif et écologiques et avons une garantie de satisfaction client.'
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="col-sm-services">
              <CustomTypo type={'footerTitle'} text={'Nos Services'} />
              <ul className="services-list-elements">
                {companyServices.map((value) => (
                  <li className="service-element">
                    <img
                      src={marker}
                      alt="marker-list.svg"
                      className="list-icon"
                    />
                    <CustomTypo type={'footerText'} text={value.title} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="col-sm-services">
              <CustomTypo
                type={'footerTitle'}
                text={'Informations de contact'}
              />
              <ul className="contact-list-elements">
                <li className="contact-element">
                  <PlaceIcon className="contact-icon-info" />
                  <CustomTypo type={'footerText'} text={companyInfo.location} />
                </li>
                <li className="contact-element">
                  <PhoneInTalkIcon className="contact-icon-info" />
                  <CustomTypo
                    type={'footerText'}
                    text={companyInfo.phoneNumber}
                  />
                </li>
                <li className="contact-element">
                  <MailOutline className="contact-icon-info" />
                  <CustomTypo type={'footerText'} text={companyInfo.email} />
                </li>
                <li className="contact-element">
                  <AccessTimeIcon className="contact-icon-info" />
                  <CustomTypo
                    type={'footerText'}
                    text={`Du ${companyInfo.openingDays[0]} au ${companyInfo.openingDays[5]} de ${companyInfo.openingTime.hourFormat.openAt} à ${companyInfo.openingTime.hourFormat.closedAt}`}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottomline-container">
        <div className="footer-bottomline-content">
          <CustomTypo
            type={'footerCopyright'}
            text={'© 2024 Net Cleaning Services. Privacy Policy'}
          />
        </div>
      </div>
    </div>
  )
}

export default Footer
