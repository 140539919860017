import React from 'react'

import './styles/TopHeader.css'

import PlaceIcon from '@mui/icons-material/Place'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'

import { companyInfo } from '../../data/data'

const TopHeader = () => {
  return (
    <div className="top-header">
      <div className="elements-info">
        <PlaceIcon className="icon-info" />
        <p className="text-info">{companyInfo.location}</p>
      </div>
      <div className="elements-info">
        <AccessTimeIcon className="icon-info" />
        <p className="text-info">
          {`${companyInfo.openingTime.dotFormat.openAt} - ${companyInfo.openingTime.dotFormat.closedAt}`}{' '}
        </p>
      </div>
      <div className="elements-info">
        <PhoneInTalkIcon className="icon-info" />
        <p className="text-info">{companyInfo.phoneNumber}</p>
      </div>
    </div>
  )
}

export default TopHeader
