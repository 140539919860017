import React from 'react'

import './styles/Service.css'

import Header from '../components/common/Header'

import { Link } from 'react-router-dom'

import { PUBLIC_ROUTES } from '../routes/routes'

import { servicesPages } from '../data/servicesPages'
import { companyServices } from '../data/data'

import CustomTypo from '../components/custom/components/CustomTypo'

import marker from '../assets/icons/marker-list.svg'

import { useNavigate } from 'react-router-dom'
import { Margin } from '@mui/icons-material'

const Service = ({ id, image }) => {
  const desiredId = id

  const selectedService = servicesPages.find(
    (service) => service.id === desiredId
  )

  const navigate = useNavigate()

  const handleClick = (path) => {
    navigate(path)
  }

  return (
    <>
      <div className="service-main-container">
        <div className="service-left-section">
          <div className="service-image-container">
            <img
              src={image}
              alt="house-appartment-villa "
              className="service-image-style"
            />
          </div>
          <div className="service-text-container">
            <CustomTypo
              type={'serviceTitleText'}
              text={selectedService.title}
            />
          </div>
          {selectedService.intro ? (
            <div className="service-text-container">
              <CustomTypo
                type={'serviceNormalText'}
                text={selectedService.intro}
              />
            </div>
          ) : null}
          {selectedService['hwp-q'] ? (
            <div className="service-text-container">
              <CustomTypo
                type={'serviceQuestionText'}
                text={selectedService['hwp-q']}
              />
            </div>
          ) : null}
          {selectedService['hwp-r'] ? (
            <div className="service-text-container">
              <CustomTypo
                type={'serviceNormalText'}
                text={selectedService['hwp-r']}
              />
            </div>
          ) : null}
          <div className="service-text-container">
            <CustomTypo
              type={'serviceNormalText'}
              text={
                'Nous fournissons des services incluent les elements suivants :'
              }
            />
          </div>
          <div className="service-text-container">
            {selectedService.missions.map((mission) => (
              <div className="service-inline-flex">
                <div className="service-display-block">
                  <img
                    src={marker}
                    alt="marker-list.svg"
                    className="service-list-icon"
                  />
                </div>
                <div className="service-display-block">
                  <CustomTypo
                    type={'serviceMissionTitle'}
                    text={mission.title}
                  />
                  <CustomTypo
                    type={'serviceNormalText'}
                    text={mission.service}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="service-right-section">
          {companyServices.map((service) => (
            <button className="service-list-item-button" type="button">
              <Link to={service.path} style={{ textDecoration: 'none' }}>
                <p className="service-list-item-text">{service.title}</p>
              </Link>
            </button>
          ))}
        </div>
      </div>
      <div style={{ marginBottom: 50 + 'px' }}></div>
    </>
  )
}

export default Service
