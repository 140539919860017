/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'

import './styles/ScrollButton.css'

import Fab from '@mui/material/Fab'
import UpIcon from '@mui/icons-material/KeyboardArrowUp'

const ScrollButton = () => {
  const [showButton, setShowButton] = useState(false)

  const handleScroll = () => {
    const scrollY = window.scrollY
    const windowHeight = window.innerHeight
    const bodyHeight = document.body.offsetHeight

    // Adjust the threshold as needed
    const scrollThreshold = bodyHeight - windowHeight - 200

    setShowButton(scrollY > scrollThreshold && bodyHeight > windowHeight)
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [showButton])

  return (
    <Fab
      className={`${
        showButton ? 'scroll-button-here' : 'scroll-button-hidden'
      }`}
      color="#57d666"
      size="medium"
      aria-label="Scroll to top"
      onClick={scrollToTop}
    >
      <UpIcon />
    </Fab>
  )
}

export default ScrollButton
