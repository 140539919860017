import React from 'react'

import '../styles/CustomCard.css'
import CustomTypo from './CustomTypo'
import { Done } from '@mui/icons-material'

import houses from './../../../assets/icons-services/houses.svg'
import offices from './../../../assets/icons-services/offices.svg'
import window from './../../../assets/icons-services/window.svg'
import { Link } from 'react-router-dom'

const CustomCard = ({ title, list, tag, path, ...rest }) => {
  const handleClick = () => {
    console.log('Button clicked!')
  }

  const image = (tag) => {
    if (tag === 'houses') {
      return houses
    } else if (tag === 'offices') {
      return offices
    } else if (tag === 'window') {
      return window
    }
  }

  return (
    <div className="main-card">
      <div className="main-card-icon">
        <img src={image(tag)} alt="window.svg" height={'90px'} width={'90px'} />
      </div>
      <div className="main-card-title">{title}</div>
      <ul className="main-card-list">
        {list.map((value) => (
          <li className="main-card-list-mission">
            <Done
              color="primary"
              fontSize="small"
              style={{ marginRight: '10px' }}
            />
            <CustomTypo type={'contentListText'} text={value} />
          </li>
        ))}
      </ul>
      <button type="button" className="main-card-info-btn">
        <Link to={path} style={{ textDecoration: 'none' }}>
          <p className="main-card-info-txt">En savoir plus</p>
        </Link>
      </button>
    </div>
  )
}

export default CustomCard
