import React from 'react'

import netCleaningLogo from './../../assets/images/ncs-logo-loader.svg'

import './styles/Loading.css'

const Loading = () => {
  return (
    <div id="screen-container">
      <div id="logo-container">
        <img id="ncs-loader-logo" src={netCleaningLogo} alt="Net Cleaning" />
      </div>
    </div>
  )
}

export default Loading
