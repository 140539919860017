import React from 'react'
import Header from '../components/common/Header'
import ImageSlider from '../components/common/ImageSlider'
import Content from '../components/common/Content'
import Footer from '../components/common/Footer'

const Home = () => {
	return (
		<div>
			<ImageSlider />
			<Content />
		</div>
	)
}

export default Home
