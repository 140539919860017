import React, { Component } from 'react'

import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import ScrollButton from '../components/common/ScrollButton'

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <>
      <Header />
      <Component {...rest} />
      <Footer />
      <ScrollButton />
    </>
  )
}

export default PublicRoute
