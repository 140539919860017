import React from 'react'

import TopHeader from './TopHeader'
import SubHeader from './SubHeader'

const Header = () => {
  return (
    <div>
      <TopHeader />
      <SubHeader />
    </div>
  )
}

export default Header
