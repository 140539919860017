import React, { useEffect, useState } from 'react'

import '../styles/CustomTypo.css'

import Typography from '@mui/material/Typography'

// const styleList = []

const CustomTypo = ({ type, text, ...rest }) => {
  let [style, setStyle] = useState(null)

  useEffect(() => {
    let newStyle

    switch (type) {
      case 'headerButtonText':
        newStyle = 'headerButtonText'
        break
      case 'contentMainTitle':
        newStyle = 'contentMainTitle'
        break
      case 'contentMainText':
        newStyle = 'contentMainText'
        break
      case 'contentListText':
        newStyle = 'contentListText'
        break

      case 'serviceTitleText':
        newStyle = 'serviceTitleText'
        break
      case 'serviceNormalText':
        newStyle = 'serviceNormalText'
        break
      case 'serviceQuestionText':
        newStyle = 'serviceQuestionText'
        break
      case 'serviceMissionTitle':
        newStyle = 'serviceMissionTitle'
        break

      case 'footerTitle':
        newStyle = 'footerTitle'
        break
      case 'footerText':
        newStyle = 'footerText'
        break
      case 'footerCopyright':
        newStyle = 'footerCopyright'
        break
      default:
        newStyle = 'defaultText'
        break
    }

    setStyle(newStyle)
  }, [type])

  return (
    <Typography className={style} {...rest}>
      {text}
    </Typography>
  )
}

export default CustomTypo
