import React, { useEffect, useState } from 'react'

import '../styles/CustomButton.css'

import Button from '@mui/material/Button'

const CustomButton = ({ type, text, ...rest }) => {
  let [style, setStyle] = useState(null)

  useEffect(() => {
    let newStyle

    switch (type) {
      case 'headerButton':
        newStyle = 'headerButton'
        break
      case 'serviceListButton':
        newStyle = 'serviceListButton'
        break
      case 'contactFormButton':
        newStyle = 'contactFormbutton'
        break
      default:
        newStyle = 'defaultButtonButton'
        break
    }

    setStyle(newStyle)
  }, [type])

  return (
    <Button varriant="text" className={style} {...rest}>
      {text}
    </Button>
  )
}

export default CustomButton
