import React, { useState } from 'react'

import './styles/Contact.css'

import CustomTypo from '../components/custom/components/CustomTypo'

import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import MailOutline from '@mui/icons-material/MailOutline'

import marker from '../assets/icons/marker-list.svg'

import { companyInfo, companyAdvantages } from '../data/data'

const Contact = () => {
  return (
    <>
      <div className="contact-main-container">
        <div className="contact-title-container">
          <CustomTypo type={'serviceTitleText'} text={'Contactez nous'} />
        </div>
        <div className="contact-form-container">
          <div className="contact-container-center">
            {/* <div className="contact-title-container">
              <CustomTypo type={'serviceTitleText'} text={'Informations'} />
            // </div> */}
            <div className="container-content">
              <div className="contact-info-container">
                <div className="contact-info-elements-flex">
                  <PhoneInTalkIcon className="contact-icon-info" />
                  <div className="contact-info-elements-block">
                    <CustomTypo
                      type={'serviceQuestionText'}
                      text={'Téléphone'}
                    />
                    <CustomTypo
                      type={'serviceNormalText'}
                      text={companyInfo.phoneNumber}
                    />
                  </div>
                </div>
                <div className="contact-info-elements-flex">
                  <MailOutline className="contact-icon-info" />
                  <div className="contact-info-elements-block">
                    <CustomTypo type={'serviceQuestionText'} text={'Email'} />
                    <CustomTypo
                      type={'serviceNormalText'}
                      text={companyInfo.email}
                    />
                  </div>
                </div>
                <div className="contact-info-elements-flex">
                  <AccessTimeIcon className="contact-icon-info" />
                  <div className="contact-info-elements-block">
                    <CustomTypo
                      type={'serviceQuestionText'}
                      text={'Horaires'}
                    />
                    <CustomTypo
                      type={'serviceNormalText'}
                      text={`Du ${companyInfo.openingDays[0]} au ${companyInfo.openingDays[5]}`}
                    />
                    <CustomTypo
                      type={'serviceNormalText'}
                      text={`de ${companyInfo.openingTime.dotFormat.openAt}h à ${companyInfo.openingTime.dotFormat.closedAt}h`}
                    />
                  </div>
                </div>
              </div>
              <div className="contact-advantages-company">
                <div className="contact-advantages-title">
                  <CustomTypo
                    type={'serviceMissionTitle'}
                    text={companyAdvantages.contactUs}
                  />
                </div>
                <div>
                  {companyAdvantages.advantages.map((advantage) => (
                    <div className="contact-advantages-points">
                      <div className="contact-icon-list">
                        <img
                          src={marker}
                          alt="marker-list.svg"
                          className="service-list-icon"
                        />
                      </div>
                      <CustomTypo type={'serviceNormalText'} text={advantage} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="contact-container-right">
            <div className="contact-title-container">
              <CustomTypo type={'serviceTitleText'} text={'Ecrivez-nous'} />
            </div>
            <form onSubmit={submitHandler} className="form" method="POST">
              <div className="contact-input-form">
                <div className="contact-input-element-2">
                  <div className="contact-input-md">
                    <TextField
                      className="contact-text-field"
                      label="Nom"
                      name="lastname"
                      variant="outlined"
                      onChange={changeHandler}
                      // required
                      fullWidth
                    />
                    <p className="contact-form-error">
                      {state.error.lastname ? state.error.lastname : ''}
                    </p>
                  </div>
                  <div className="contact-input-md">
                    <TextField
                      className="contact-text-field"
                      label="Prénom"
                      name="firstname"
                      variant="outlined"
                      onChange={changeHandler}
                      // required
                      fullWidth
                    />

                    <p className="contact-form-error">
                      {state.error.firstname ? state.error.firstname : ''}
                    </p>
                  </div>
                </div>
                <div className="contact-input-element">
                  <TextField
                    className="contact-text-field"
                    label="Email"
                    name="email"
                    variant="outlined"
                    onChange={changeHandler}
                    // required
                    fullWidth
                  />
                  <p className="contact-form-error">
                    {state.error.email ? state.error.email : ''}
                  </p>
                </div>
                <div className="contact-input-element">
                  <TextField
                    className="contact-text-field"
                    label="Message"
                    name="message"
                    variant="outlined"
                    onChange={changeHandler}
                    multiline
                    rows={4}
                    fullWidth
                  />
                  <p>{state.error.message ? state.error.message : ''}</p>
                </div>
                <div className="contact-button-div">
                  <button
                    type="submit"
                    className="contact-form-button"
                    // onClick={handleClick}
                  >
                    <p className="main-card-info-txt">Envoyer</p>
                  </button>
                </div>
              </div>
              {() => {
                if (state.isSent === null) {
                } else if (state.isSent === true) {
                  return (
                    <AlertPopUp
                      type="success"
                      title="Your request has been sent!"
                      message="We will contact soon!"
                    />
                  )
                } else if (state.isSent === false) {
                  return (
                    <AlertPopUp
                      type="error"
                      title="An error occured"
                      message="Your request hasn't been sent"
                    />
                  )
                }
              }}
            </form>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Contact
