import React, { useState, useEffect } from 'react'

import { Routes, Route, useLocation } from 'react-router-dom'

import { servicesPages } from './data/servicesPages'

import PublicRoute from './routes/PublicRoute'

import Loading from './components/common/Loading'

import Home from './views/Home'
import Contact from './views/Contact'
import Service from './views/Service'

const App = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Function to simulate loading for 3 seconds
    const simulateLoading = async () => {
      setLoading(true)
      // Simulate loading for 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000))
      setLoading(false)
    }

    simulateLoading()
  }, [location])

  return (
    <>
      {loading && <Loading />}
      {!loading && (
        <Routes>
          <Route path="/" element={<PublicRoute component={Home} />} />
          {servicesPages.map((service) => (
            <Route
              key={'null'}
              path={service.path}
              element={
                <PublicRoute
                  component={Service}
                  id={service.id}
                  image={service.image}
                />
              }
            />
          ))}
          <Route
            path="/contact"
            element={<PublicRoute component={Contact} />}
          />
        </Routes>
      )}
    </>
  )
}

export default App
