import React, { useEffect } from 'react'

import './styles/Content.css'

import { homePageContent } from '../../data/homePageContent'

import CustomTypo from '../custom/components/CustomTypo'
import CustomCard from '../custom/components/CustomCard'

const Content = () => {
  return (
    <div className="main-container">
      <div className="content-container">
        <CustomTypo
          type={'contentMainTitle'}
          text={homePageContent.header.title}
        />
      </div>
      <div className="content-container">
        <CustomTypo
          type={'contentMainText'}
          text={homePageContent.header.text}
        />
      </div>
      <div className="card-carousel">
        {homePageContent.cards.map((card) => (
          <CustomCard
            key={card.id}
            tag={card.tag}
            title={card.title}
            list={card.missions}
            path={card.path}
          />
        ))}
      </div>
    </div>
  )
}

export default Content
