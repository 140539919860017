export const PUBLIC_ROUTES = {
  HOME: '/',
  SERVICE: '/service',
  HOUSES_CLEANING: '/house-and-appartment',
  OFFICES_CLEANING: '/offices-and-workspaces',
  COMMERCIAL_CLEANING: '/commercial-spaces',
  RESIDENTIAL_CLEANING: '/residential',
  WINDOWS_CLEANING: '/windows-cleaning',
  CONSTRUCTION_CLEANING: '/construction-cleaning',
  CONTACT: '/contact',
}

export const PRIVATE_ROUTES = {}
