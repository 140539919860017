import React, { useEffect, useState } from 'react'

import './styles/ImageSlider.css'

import slide1 from '../../assets/images/slider/slide-1.jpg'
import slide2 from '../../assets/images/slider/slide-2.jpg'
import slide3 from '../../assets/images/slider/slide-3.jpg'

const ImageSlider = () => {
  const images = [slide1, slide2, slide3]

  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 4000)

    return () => clearInterval(intervalId)
  }, [currentIndex, images.length])

  return (
    <div id="slider-container">
      <div className="slider">
        {images.map((imageUrl, index) => (
          <img
            key={index}
            src={imageUrl}
            alt={`Slide ${index + 1}`}
            className={
              index === currentIndex ? 'image appear' : 'image disappear'
            }
          />
        ))}
      </div>
    </div>
  )
}

export default ImageSlider
