import React, { useState } from 'react'

import './styles/SubHeader.css'

import ncsLogo from '../../assets/images/ncs-logo.png'

import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fade from '@mui/material/Fade'

import { companyServices } from '../../data/data'
import CustomButton from '../custom/components/CustomButton'
import CustomTypo from '../custom/components/CustomTypo'

import { PUBLIC_ROUTES } from '../../routes/routes'

import { Link } from 'react-router-dom'

const SubHeader = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div className="sub-header">
      <div className="sub-header-container-logo">
        <img className="sub-header-logo" src={ncsLogo} alt="Net Cleaning" />
      </div>

      <div className="sub-header-container-elements">
        <Stack spacing={2} direction="row">
          <CustomButton
            type="headerButton"
            text={<CustomTypo type={'headerButtonText'} text={'Accueil'} />}
            component={Link}
            to={PUBLIC_ROUTES.HOME}
          />
          <CustomButton
            type="headerButton"
            text={<CustomTypo type={'headerButtonText'} text={'Services'} />}
            id="fade-button"
            aria-controls={anchorEl ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          />
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            {companyServices.map((element) => (
              <MenuItem
                key={element.id}
                onClick={handleClose}
                component={Link}
                to={`${element.path}`}
              >
                {element.title}
              </MenuItem>
            ))}
          </Menu>
          <CustomButton
            type="headerButton"
            text={<CustomTypo type={'headerButtonText'} text={'Contact'} />}
            component={Link}
            to={PUBLIC_ROUTES.CONTACT}
          ></CustomButton>
        </Stack>
      </div>

      <div className="sub-header-container-empty"></div>
    </div>
  )
}

export default SubHeader
