import { PUBLIC_ROUTES } from '../routes/routes'
import { services } from './data'

export const homePageContent = {
	header: {
		title: 'Nos services de nettoyage',
		text: "Laissez-nous mettre à profit nos années d'expérience, nos employés qualifiés et nos procédures avancées, pour vous garantir un environnement propre et sain à vos employés, à vos clients et à vos invités.",
	},

	cards: [
		{
			id: 1,
			tag: 'offices',
			title: 'Bureaux & Espaces de travail',
			missions: [
				'Nettoyage des sols',
				'Nettoyage des sanitaires',
				'Partie communes',
			],
			path: PUBLIC_ROUTES.OFFICES_CLEANING,
		},
		{
			id: 2,
			tag: 'houses',
			title: 'Copropriétés & Résidences',
			missions: [
				"Nettoyage des halls d'entrées",
				'Escalier et ascenseur',
				'Portes et vitres',
			],
			path: PUBLIC_ROUTES.RESIDENTIAL_CLEANING,
		},
		{
			id: 5,
			tag: 'window',
			title: 'Vitres / Verranda / Garde corps vitré',
			missions: ['Grattage et dégraissage', 'Raclage des vitres', 'Lustrage'],
			path: PUBLIC_ROUTES.WINDOWS_CLEANING,
		},
	],
}
