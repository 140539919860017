import { PUBLIC_ROUTES } from '../routes/routes'

export const companyServices = [
  {
    id: 1,
    title: 'Bureaux - Espaces de travail',
    path: PUBLIC_ROUTES.OFFICES_CLEANING,
  },
  {
    id: 2,
    title: 'Copropriétés - Résidences',
    path: PUBLIC_ROUTES.RESIDENTIAL_CLEANING,
  },
  {
    id: 3,
    title: 'Appartement, Villa, Penthouse',
    path: PUBLIC_ROUTES.HOUSES_CLEANING,
  },
  {
    id: 4,
    title: 'Locaux commerciaux',
    path: PUBLIC_ROUTES.COMMERCIAL_CLEANING,
  },
  {
    id: 5,
    title: 'Nettoyage des vitres',
    path: PUBLIC_ROUTES.WINDOWS_CLEANING,
  },
  {
    id: 6,
    title: 'Nettoyage après travaux',
    path: PUBLIC_ROUTES.CONSTRUCTION_CLEANING,
  },
]

export const companyInfo = {
  phoneNumber: '+33 (0)7 69 35 33 99',
  email: 'contact@net-cleaning.fr',
  openingTime: {
    hourFormat: {
      openAt: '07h30',
      closedAt: '19h30',
    },
    dotFormat: {
      openAt: '07:30',
      closedAt: '19:30',
    },
  },
  openingDays: ['lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
  location: 'Antibes - Cannes - Monaco',
}

export const companyAdvantages = {
  contactUs: 'Contactez-nous pour votre devis gratuit',
  advantages: [
    'Contrat sur mesure adapté à vos besoins',
    'Tarifs compétitifs',
    'Un travail de qualité',
    'Un agenda de services conçu pour une flexibilité',
  ],
}
