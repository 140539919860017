import { PUBLIC_ROUTES } from '../routes/routes'

import service_1 from '../assets/images/services/service-1-offices-and-workspaces.jpg'
import service_2 from '../assets/images/services/service-2-residential.jpg'
import serivce_3 from '../assets/images/services/service-3-house-and-appartment.jpg'
import service_4 from '../assets/images/services/service-4-commercial-spaces.jpg'
import service_5 from '../assets/images/services/service-5-window-cleaning.jpg'
import service_6 from '../assets/images/services/service-6-construction-cleaning.jpg'

export const servicesPages = [
  {
    id: 1,
    path: PUBLIC_ROUTES.OFFICES_CLEANING,
    image: service_1,
    title: 'Bureaux et Espaces de Travail',
    intro:
      "Nous comprenons l'importance d'un environnement propre et bien entretenu pour la productivité et le bien-être de vos employés. Grâce à notre équipe de professionnels expérimentés et à l'utilisation de produits de nettoyage de haute qualité, nous sommes en mesure de fournir un service de nettoyage complet et efficace. Que ce soit pour l'aspiration des sols, le dépoussiérage des surfaces, le nettoyage des sanitaires ou la gestion des déchets, nous sommes là pour vous offrir un environnement de travail impeccable.",
    'hwp-q': 'Comment procédons nous au nettoyage de vos espaces de travail',
    'hwp-r':
      'Nous suivons une approche méthodique pour garantir un nettoyage efficace et de qualité dans nos services de nettoyage des bureaux et des espaces de travail. Notre équipe expérimentée commence par évaluer vos besoins spécifiques et élaborer un plan de nettoyage personnalisé. Nous utilisons ensuite des techniques et des produits adaptés pour dépoussiérer, aspirer, laver les surfaces et désinfecter les espaces, y compris les sanitaires. Nous veillons à ce que chaque zone soit soigneusement nettoyée, des bureaux aux couloirs, en passant par les zones de réception et les espaces communs. Notre engagement envers la propreté et notre souci du détail sont au cœur de notre approche de nettoyage.',
    missions: [
      {
        title: 'Bureaux',
        service:
          'Notre équipe prend en charge le dépoussiérage minutieux des surfaces, le bureau, la chaise de travail, étagère, armoires, nous veillons également à ne pas toucher les zones sensibles tel que les écrans et les claviers. Nous nous assurons que chaque bureau est bien organisé et propre, offrant un environnement de travail agréable.',
      },
      {
        title: 'Espaces communs',
        service:
          "Pour les couloirs, les salles de réunion ou les zones de détente, nous nous assurons de les garder propres et accueillantes. Nous effectuons le balayage, l'aspiration et le lavage des sols, tout en éliminant efficacement les saletés, les taches et les résidus. Notre objectif est de maintenir un environnement commun impeccable, favorisant une atmosphère agréable et professionnelle pour tous.",
      },
      {
        title: 'Sanitaires',
        service:
          'Nos experts en nettoyage assurent la désinfection, le détartrage et le nettoyage complet des toilettes, y compris les cuvettes, les lavabos et les miroirs. Ils veillent également à recharger les fournitures sanitaires, créant ainsi un espace de toilettes frais et impeccable.',
      },
      {
        title: 'Poubelles',
        service:
          'La gestion efficace des déchets fait partie de nos services. Nous veillons à vider régulièrement les poubelles, à remplacer les sacs et à maintenir la propreté des bacs à déchets. Notre objectif est de maintenir un environnement propre et hygiénique, tout en assurant une élimination responsable des déchets.',
      },
    ],
  },
  {
    id: 2,
    path: PUBLIC_ROUTES.RESIDENTIAL_CLEANING,
    image: service_2,
    title: 'Résidences et Copropriétés',
    intro:
      "Dans notre entreprise de nettoyage, nous nous spécialisons dans l'entretien des résidences et des copropriétés. Nous comprenons l'importance de maintenir des espaces communs propres et accueillants pour les résidents. Notre équipe dédiée prend en charge le nettoyage des entrées, des escaliers, des couloirs et des zones communes, en utilisant des techniques et des produits adaptés. Nous veillons à éliminer la saleté, la poussière et les débris, tout en accordant une attention particulière aux détails. Avec notre service de nettoyage professionnel, nous contribuons à créer un environnement agréable et propre pour tous les résidents, favorisant ainsi un cadre de vie confortable et harmonieux.",
    'hwp-q':
      "Comment procédons nous au nettoyage et à l'entretient de vos résidences et copropriétés ?",
    'hwp-r': '',
    missions: [
      {
        title: "Nettoyage des halls d'entrée",
        service:
          "Notre équipe se charge de maintenir les entrées et les halls d'entrée propres et accueillants. Nous effectuons le balayage, le lavage des sols, le dépoussiérage des surfaces, le nettoyage des portes et des vitres, ainsi que le vidage des poubelles. Nous créons ainsi une première impression positive pour les résidents et les visiteurs.",
      },
      {
        title: 'Entretient des escaliers et des couloirs',
        service:
          'Nous nous occupons du nettoyage régulier des escaliers et des couloirs, en éliminant les saletés, les traces et les débris. Nous aspirons, balayons et lavons les sols, nettoyons les rampes et les mains courantes, afin de maintenir un environnement sûr et propre pour les résidents.',
      },
      {
        title: 'Gestion des espaces communs',
        service:
          "Nous prenons en charge l'entretien des espaces communs tels que les salles de réunion, les salles de sport, les aires de jeux et les jardins communs. Notre équipe assure le nettoyage des surfaces, le vidage des poubelles, l'entretien des équipements et la gestion des déchets, afin de créer des espaces conviviaux et agréables pour les résidents.",
      },
      {
        title: 'Nettoyage des parties communes intérieures et extérieures',
        service:
          "Nous nous occupons du nettoyage complet des parties communes, à la fois à l'intérieur et à l'extérieur des résidences et des copropriétés. Cela comprend le nettoyage des vitres, le dépoussiérage des surfaces, le balayage des parkings, l'entretien des aires de stationnement, ainsi que le nettoyage des espaces de rangement. Nous travaillons de manière proactive pour maintenir un environnement propre et attrayant pour tous les résidents.",
      },
    ],
  },
  {
    id: 3,
    path: PUBLIC_ROUTES.HOUSES_CLEANING,
    image: serivce_3,
    title: 'Appartement, Penthouse, Villa',
    intro: '',
    'hwp-q': 'Comment fonctionne notre programme de nettoyage de domicile ?',
    'hwp-r':
      'Nous commençons par un nettoyage approfondi des détails dans toute votre maison au cours des deux premiers nettoyages. Lors du premier nettoyage, notre service de ménage nettoiera votre appartement en profondeur, avec une attention particulière pour votre cuisine et vos salles de bain. Lors de la deuxième session, nous nettoierons tout votre appartement, mais cette fois, nous fournirons des services de nettoyage des détails dans vos zones de couchage et de vie. Nous continuerons à maintenir ce niveau de propreté dans toute votre maison lors de nos prochaines visites en fournissant des services de nettoyage en profondeur à tour de rôle.',
    missions: [
      {
        title: 'Salon / Espace de vie',
        service:
          "Surfaces essuyées à la main, toiles d'araignées enlevées, portes et cadres de porte nettoyés, époussetage général, sols nettoyés.",
      },
      {
        title: "Salle d'eau",
        service:
          'Dépoussiérage, sols nettoyés, toilettes nettoyées, miroirs / accessoires chromés nettoyés, portes de douche nettoyées, murs carrelés, baignoire / douches nettoyées.',
      },
      {
        title: 'Cuisine',
        service:
          "Comptoirs, extérieur de la hotte de cuisine, haut et devant de la cuisinière, bacs d'égouttage, et surfaces supérieures en verre : impeccables. Éviers, façades des appareils, micro-ondes, portes et cadres de porte : étincelants. L'époussetage général élimine toiles d'araignées et saletés, assurant un environnement parfaitement nettoyé.",
      },
      {
        title: 'Chambres',
        service:
          "Surfaces essuyées à la main, sols nettoyés, époussetage général, toiles d'araignée enlevées, portes et cadres de porte nettoyés.",
      },
    ],
  },
  {
    id: 4,
    path: PUBLIC_ROUTES.COMMERCIAL_CLEANING,
    image: service_4,
    title: 'Locaux commerciaux',
    intro:
      "Dans notre entreprise de nettoyage, nous sommes spécialisés dans le nettoyage et l'entretien des boutiques et des locaux commerciaux. Nous comprenons l'importance d'une présentation impeccable de votre espace pour attirer et fidéliser vos clients. Notre équipe expérimentée se charge de dépoussiérer, d'aspirer, de laver les surfaces, de nettoyer les vitrines et de maintenir une propreté impeccable dans vos locaux. Nous utilisons des produits de nettoyage de haute qualité et adoptons une approche méthodique pour garantir un résultat exceptionnel. Faites confiance à notre expertise pour maintenir vos boutiques et vos locaux commerciaux dans un état optimal de propreté, créant ainsi une atmosphère accueillante pour vos clients.",
    'hwp-q':
      "Comment procédons nous au nettoyage et à l'entretient de vos locaux ?",
    'hwp-r':
      "Nous suivons une approche méthodique pour assurer un nettoyage efficace et complet de vos boutiques et locaux commerciaux. Notre équipe débute par une évaluation approfondie de vos besoins spécifiques, ce qui nous permet de concevoir un plan de nettoyage sur mesure pour votre établissement. Nous nous occupons de tous les détails, depuis le dépoussiérage des surfaces, le nettoyage des vitrines et des étagères, jusqu'au lavage des sols et l'élimination des déchets. Nous accordons une attention particulière aux zones à fort trafic pour garantir une propreté irréprochable dans vos espaces. Notre priorité est de créer un environnement accueillant et impeccable qui met en valeur votre boutique ou votre espace commercial, pour offrir une expérience agréable à vos clients.",
    missions: [
      {
        title: "Vitrines et surfaces d'exposition",
        service:
          "Notre équipe spécialisée s'occupe de nettoyer et de faire briller les vitrines, les présentoirs et les surfaces d'exposition. Nous utilisons des techniques et des produits adaptés pour éliminer les traces, la poussière et les taches, mettant ainsi en valeur vos produits et attirant l'attention des clients.",
      },
      {
        title: 'Espaces de vente',
        service:
          "Nous accordons une grande importance à la propreté des zones de vente, des comptoirs de paiement aux rayons. Notre équipe assure un dépoussiérage minutieux, un nettoyage des sols et une gestion des déchets efficace pour maintenir un environnement propre et ordonné, favorisant une expérience d'achat agréable pour vos clients.",
      },
      {
        title: 'Façade extérieure',
        service:
          'Nous offrons également des services de nettoyage pour la façade extérieure de votre boutique ou de votre local commercial. Notre équipe utilise des techniques spécialisées pour éliminer la saleté, les dépôts, les traces et les taches sur les surfaces extérieures, y compris les murs, les vitrines et les enseignes. Nous veillons à ce que votre façade soit propre, attrayante et professionnelle, créant ainsi une première impression positive pour vos clients dès leur arrivée.',
      },
      {
        title: 'Espaces communs',
        service:
          'Les espaces communs, tels que les aires de repos, les salles de repos et les zones de restauration, sont également pris en charge par notre équipe. Nous nous assurons que ces zones sont soigneusement nettoyées, y compris les tables, les chaises, les appareils électroménagers et les surfaces de préparation des aliments, pour garantir un environnement hygiénique pour votre personnel et vos clients.',
      },
    ],
  },
  {
    id: 5,
    path: PUBLIC_ROUTES.WINDOWS_CLEANING,
    image: service_5,
    title: 'Nettoyage des vitres',
    intro: '',
    'hwp-q': 'Comment fonctionne notre programme de nettoyage de vitres ?',
    'hwp-r': '',
    missions: [
      {
        title: 'Le dépoussiérage des encadrements des vitres',
        service:
          'Cela consiste à dépoussiérer les contours de fenêtres avec un chiffon pour éviter que ces résidus ne se déposent sur les surfaces vitrées une fois propres, pour éliminer la poussière et les saletés.',
      },
      {
        title: 'Le grattage et le dégraissage',
        service:
          "Ces actions optionnelles s'ajoutent au dépoussiérage dans le cas de vitres particulièrement souillées. Les masses grasses en sont éliminées, de même que les salissures (fientes d'oiseaux, souillures de la rue, etc.)",
      },
      {
        title: "L'essuyage des bordures",
        service:
          "Nous prenons le soin d'essuyer les bordures pour éviter que l'eau sale ne souille les boiseries des vitres",
      },
      {
        title: 'Le raclage des vitres',
        service:
          "La raclette est positionnée à l'horizontale, en partant du côté haut droit de la vitre. La raclette est ensuite séchée entre chaque passage avec un autre chiffon propre. Les bandes de passage doivent être superposées pour éviter les traces sur les vitres et s'assurer de bien éliminer toute l'eau sale",
      },
      {
        title: 'Le lustrage',
        service:
          "Le lustrage des vitres à l'aide d'un chiffon adapté afin d'obtenir un résultat parfait. Pour éliminer les éventuelles traces causées par le mouilleur.",
      },
    ],
  },
  {
    id: 6,
    path: PUBLIC_ROUTES.CONSTRUCTION_CLEANING,
    image: service_6,
    title: 'Nettoyage après travaux',
    intro:
      "Dans notre entreprise de nettoyage, nous proposons des services spécialisés dans le nettoyage après travaux. Que vous ayez effectué des rénovations, des constructions ou des travaux de réparation, notre équipe expérimentée se charge de rendre votre espace propre, ordonné et prêt à être utilisé. Nous nous occupons du dépoussiérage en profondeur, de l'élimination des débris, du nettoyage des surfaces, des vitres et des sols, afin de vous offrir un environnement impeccable et accueillant après les travaux. Faites confiance à notre expertise pour transformer votre espace en un lieu propre et prêt à être occupé",
    'hwp-q': '',
    'hwp-r': '',
    missions: [
      {
        title: 'Élimination des débris',
        service:
          "Nous prenons en charge l'enlèvement et la gestion des débris laissés par les travaux. Qu'il s'agisse de matériaux de construction, d'emballages ou de déchets divers, nous nous occupons de leur évacuation responsable et de leur élimination conforme aux normes en vigueur.",
      },
      {
        title: 'Dépoussiérage approfondi',
        service:
          "Après les travaux, notre équipe se charge d'un dépoussiérage minutieux pour éliminer les résidus de construction et la poussière fine qui peuvent s'être accumulés. Nous utilisons des techniques et des outils spécialisés pour nettoyer les surfaces, les recoins difficiles d'accès et les zones sensibles.",
      },
      {
        title: 'Nettoyage des surfaces',
        service:
          "Notre équipe effectue un nettoyage en profondeur des surfaces, qu'il s'agisse de murs, de sols, de portes, de fenêtres ou de toute autre surface ayant été affectée par les travaux. Nous utilisons des produits adaptés pour éliminer les taches, les marques et les résidus de peinture ou de colle, redonnant ainsi à vos surfaces leur aspect neuf et propre.",
      },
    ],
  },
]
